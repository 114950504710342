<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>

      <!-- Title -->
      <v-card-title>
        <h3>{{ $t('suppliers.tabs.factory-performances.dialog.title') }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>

            <!-- Losses -->
            <v-col cols="12" class="mt-12">
              <v-text-field
                  outlined
                  :rules="requiredRules"
                  :label="$t('suppliers.tabs.factory-performances.labels.material_losses')"
                  maxlength="100"
                  hide-details
                  v-model="form.losses"
              />
            </v-col>

            <!-- Transportations -->
            <v-col cols="12">
              <SelectGeneric
                  is-multiple
                  :return-object="false"
                  :slot-item="{text: 'value', value: 'value'}"
                  :items="$t('constants.factoryPerformanceTransportations.list')"
                  :label="$t('suppliers.tabs.factory-performances.labels.transportations')"
                  :value="form.transportations"
                  @input="onInputTransportations"
              />
            </v-col>

            <!-- Program participation -->
            <v-col cols="12">
              <v-switch
                  v-model="form.hasProgramImpactParticipation"
                  :label="$t('suppliers.tabs.factory-performances.labels.hasProgramImpactParticipation')"
              />
            </v-col>

            <!-- Heat type -->
            <v-col cols="12">
              <SelectGeneric
                :items="$t('constants.factoryPerformanceHeatType.list')"
                :slot-item="{text: 'value', value: 'value'}"
                :label="$t('suppliers.tabs.factory-performances.labels.heatType')"
                :return-object="false"
                :value="form.heatType"
                @input="onInputHeatType"
              />
            </v-col>

            <!-- Has Green Electricity equipment -->
            <v-col cols="12">
              <v-switch
                  v-model="form.hasGreenElectricityEquipment"
                  :label="$t('suppliers.tabs.factory-performances.labels.hasGreenElectricityEquipment')"
              />
            </v-col>

            <!-- Green Electricity equipment kind  -->
            <v-col cols="12">
              <v-textarea
                  v-model="form.greenElectricityEquipmentKind"
                  :label="$t('suppliers.tabs.factory-performances.labels.greenElectricityEquipmentKind')"
                  hide-details
                  outlined
              />
            </v-col>

            <!-- Electricity consumption -->
            <v-col cols="12">
              <v-text-field
                  type="number"
                  v-model="form.electricityConsumptionPerMonth"
                  :label="$t('suppliers.tabs.factory-performances.labels.electricityConsumptionPerMonth')"
                  hide-details
                  outlined
              />
            </v-col>

            <!-- In House effluent treatment system -->
            <v-col cols="12">
              <v-switch
                  v-model="form.hasInHouseEffluentTreatmentSystem"
                  :label="$t('suppliers.tabs.factory-performances.labels.hasInHouseEffluentTreatmentSystem')"
              />
            </v-col>

            <!-- In house effluent kind -->
            <v-col cols="12">
              <v-textarea
                  v-model="form.inHouseEffluentKind"
                  :label="$t('suppliers.tabs.factory-performances.labels.inHouseEffluentKind')"
                  hide-details
                  outlined
              />
            </v-col>

            <!-- Energy efficient production -->
            <v-col cols="12">
              <v-switch
                  v-model="form.hasEnergyEfficientInvestment"
                  :label="$t('suppliers.tabs.factory-performances.labels.hasEnergyEfficientInvestment')"
              />
            </v-col>

            <!-- Energy efficient kind -->
            <v-col cols="12">
              <v-textarea
                  v-model="form.energyEfficientKind"
                  :label="$t('suppliers.tabs.factory-performances.labels.energyEfficientKind')"
                  hide-details
                  outlined
              />
            </v-col>

            <v-col cols="12" class="mt-4">
              <AlertBar />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <ButtonBarFormDialog
            class="mx-3"
            :is-loading-btn-save="isLoadingBtn.btnSave"
            @submit="customSubmit"
            @cancel="close" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin    from "@/mixins/dialog"
import formRules      from "@/mixins/formRules";

export default {
  name: "SupplierPerformanceDialog",

  mixins: [dialogMixin, formRules],

  props: {
    supplierId: { type: String, default: null }
  },

  components: {
    SelectGeneric: () => import("@/components/Common/Inputs/SelectGeneric"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () => import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  data() {
    return {
      isLoadingBtn: {btnSave: false},
      defaultForm: {
        losses: 0,
        transportations: [],
        hasProgramImpactParticipation: false,
        heatType: null,
        hasGreenElectricityEquipment: false,
        electricityConsumptionPerMonth: 0,
        hasInHouseEffluentTreatmentSystem: false,
        hasEnergyEfficientInvestment: false,
        energyEfficientKind: "",
        greenElectricityEquipmentKind: "",
        inHouseEffluentKind: ""
      }
    }
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        this.$http
            .put(`/suppliers/${this.supplierId}/factory-performances`, this.form, {
              headers: {Authorization: "Bearer " + this.$session.get('jwt')}
            })
            .then(res => {
              this.$store.commit("toast/showSuccess", this.$t('suppliers.tabs.factory-performances.dialog.success'));
              this.close(true);
            })
            .catch(err => {
              this.$store.commit("alert/showErrorAxios", err);
            })
            .finally(() => {
              this.isLoadingBtn.btnSave = false;
            });
      }
    },

    onInputTransportations(val) {
      this.form.transportations = val;
    },

    onInputHeatType(val) {
      this.form.heatType = val;
    }
  },

  watch: {
    "form.greenElectricityEquipmentKind": function (newVal) {
      this.form.hasGreenElectricityEquipment = (newVal && newVal.length > 0);
    },

    "form.energyEfficientKind": function (newVal) {
      this.form.hasEnergyEfficientInvestment = (newVal && newVal.length > 0);
    },

    "form.inHouseEffluentKind": function (newVal) {
      this.form.hasInHouseEffluentTreatmentSystem = (newVal && newVal.length > 0);
    }
  }
}
</script>

<style scoped>

</style>
